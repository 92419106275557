#registration {
  .name_inputs {
    display: flex;
    padding-bottom: space(6);

    > :first-child {
      padding-right: space(4);
    }

    > * {
      width: 50%;
    }
  }

  // Hide honeypot field which is only supposed to be filled out by
  // bots.
  #registration_phone_input {
    display: none;
  }
}
