#error_explanation {
  background-color: var(--ui-error-surface-color);
  color: var(--ui-on-error-surface-color);
  padding: space(2) space(4);
  margin: 0 0 space(12);
  border-radius: rounded(md);

  h2 {
    margin: space(2) 0;
    font-size: inherit;
  }

  ul {
    margin: space(2) 0;
    padding-left: space(4);
  }
}
