form > p,
fieldset > p {
  padding-bottom: space(6);
}

fieldset {
  padding: 0;
  margin: 0;
  -webkit-margin-collapse: separate;
  border: 0;

  > ul,
  > ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.quota_exhausted > h1,
legend {
  font-size: inherit;
  font-weight: 500;
  width: 100%;
  margin-bottom: space(6);
  border-bottom: solid 1px var(--ui-on-surface-color-lighter);

  > span {
    display: block;
  }
}

label {
  display: block;
  font-weight: 500;
  margin-bottom: space(1);

  abbr[title] {
    margin-left: space(0.5);
    color: var(--ui-on-surface-color-light);
    text-decoration: none;
  }
}

select,
.select2,
input[type=text],
input[type=search],
input[type=email],
input[type=number],
input[type=password],
input[type=url],
textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

select,
.select2 .select2-selection.select2-selection--single,
input[type=checkbox],
input[type=text],
input[type=search],
input[type=email],
input[type=password],
input[type=number],
input[type=url],
textarea {
  border: solid 1px var(--ui-on-surface-color-lighter);
  border-radius: rounded();

  &:focus {
    border-color: var(--ui-on-surface-color-light);
    box-shadow: 0 0 0 3px var(--ui-selection-color-light);
  }
}

.inputs > ol > li {
  padding-bottom: space(6);
}

div.buttons,
li.button,
.actions ol {
  display: flex;
  gap: space(2);
  margin-top: space(2);
  margin-bottom: space(6);
}

.buttons button,
.buttons input[type=submit],
.actions input[type=submit],
.button_to button:where(:not(.gsi-material-button)),
.button_to input[type=submit] {
  @extend %button;
  display: block;
}

.cancel a,
.link_action a {
  @extend %outlined-button;
  display: block;
}

.searchable_select .select2-selection.select2-selection--single {
  padding: space(2) 0 space(2) space(3);
  height: auto;
  width: 100%;

  .select2-selection__rendered {
    line-height: inherit;
    padding-left: 0;
    height: 20px;

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right space(2) center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: space(10);
    print-color-adjust: exact;
  }

  .select2-selection__arrow {
    display: none;
  }
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--ui-selection-color);
  color: inherit;
}

li.input {
  &.hidden {
    padding: 0;
  }

  &.radio .choice,
  &.boolean {
    padding-left: space(7);
    position: relative;

    label {
      font-weight: normal;
    }

    input {
      border: solid 1px var(--ui-on-surface-color-light);
      position: absolute;
      left: 0;
      top: space(0.5);

      &:focus {
        box-shadow: 0 0 0 2px var(--ui-surface-color),
                    0 0 0 4px var(--ui-primary-color-lighter);
      }

      &:checked {
        background-color: var(--ui-primary-color);
        border-color: transparent;
      }
    }
  }

  &.radio {
    .choices-group {
      display: flex;
    }

    .choice {
      margin-right: space(20);
      margin-top: space(2);
    }
  }

  &.subdomain {
    .url {
      display: flex;
    }

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      z-index: 1;
    }

    .url_suffix {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      border: solid 1px var(--ui-on-surface-color-lighter);
      border-left: 0;
      padding: 0 space(2);
      border-top-right-radius: rounded();
      border-bottom-right-radius: rounded();
      background-color: var(--ui-selection-color-lighter);
      color: var(--ui-on-surface-color-light);
    }

    .path {
      color: var(--ui-primary-color-light);
    }
  }
}

.inline-errors,
.inline-hints {
  margin: space(2) 0 0 0;
}

.inline-errors {
  color: var(--ui-error-color);
}

.inline-hints {
  color: var(--ui-on-surface-color-light);
}

.side_hint {
  margin: 0;
}

.embed_code p {
  margin: space(2) 0 0 0;
}

.quota_state_description {
  border: 0;
  padding: 0;
  margin: space(-2) 0 space(6) 0;
  color: var(--ui-on-surface-color-light);
}

.quota_exhausted {
  p {
    margin: 0 0 space(8) 0;
  }

  a.button {
    @extend %button;
  }
}

.or {
  margin: space(4) 0 space(10);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: space(2);
  color: var(--ui-on-surface-color-light);

  &::before,
  &::after {
    content: "";
    border-top: solid 1px var(--ui-on-surface-color-lighter);
    margin-top: 2px;
    display: block;
    width: space(12);
  }
}
