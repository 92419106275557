#escalated_payment_info {
  iframe {
    width: 100%;
  }

  .step {
    display: none;

    &.active {
      display: block;
    }
  }

  .alternatives_hint {
    margin-top: space(6);
  }
}
