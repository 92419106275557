#login {
  .password .inline-hints {
    float: right;
    margin-top: space(6);
    z-index: 1;
  }

  #user_remember_me_input {
    width: 50%;
  }
}
