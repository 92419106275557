:root {
  --ui-background-color: rgba(38, 70, 83, 0.05);

  --ui-secondary-color: #5efd84;
  --ui-secondary-color-light-solid: #{mix(#5efd84, #fff, 60%)};
  --ui-secondary-color-lighter-solid: #{mix(#5efd84, #fff, 30%)};
  --ui-on-secondary-color: var(--ui-primary-color);
  --ui-secondary-color-dark: #225c30;

  --ui-warning-color: #ff7400;
}
