#survey {
  form {
    margin-top: space(8);
  }

  .radio {
    padding-bottom: space(2);
  }

  .choice {
    border: solid 1px var(--ui-on-surface-color-lightest);
    border-radius: rounded();
    padding: space(4);
    margin: 0;
    display: flex;
    align-items: center;

    &:hover {
      border-color: var(--ui-on-surface-color-lighter);
    }

    &:has(:checked) {
      border-color: var(--ui-on-surface-color-light);
    }

    &:focus-within {
      box-shadow: 0 0 0 3px var(--ui-selection-color-light);
    }

    input {
      position: static;
      margin-right: space(4);
    }
  }

  legend {
    border-bottom: 0;
    margin-bottom: space(2);
  }

  fieldset {
    margin-bottom: space(4);
  }

  .action {
    display: block;
    flex: 1;

    input {
      padding: space(4);
      width: 100%;
    }
  }
}
