%button {
  border-radius: rounded();
  background-color: var(--ui-primary-color);
  border: solid 1px var(--ui-primary-color);
  color: var(--ui-on-primary-color);
  font-family: inherit;
  font-weight: 500;
  line-height: inherit;
  padding: space(1.5) space(4) space(2);
  text-decoration: none;
  white-space: nowrap;

  &:focus {
    box-shadow: 0 0 0 3px var(--ui-primary-color-lighter);
  }

  &:hover:not(:disabled) {
    opacity: 0.92;
  }

  &[disabled] {
    background-color: var(--ui-on-surface-color-lightest-solid);
    border-color: var(--ui-on-surface-color-lightest-solid);
    color: var(--ui-primary-color-light);
    cursor: default;
  }
}

%outlined-button {
  border-radius: rounded();
  background-color: transparent;
  border: solid 1px var(--ui-primary-color-light);
  color: var(--ui-primary-color);
  font-family: inherit;
  font-weight: 500;
  line-height: inherit;
  padding: space(1.5) space(4) space(2);
  text-decoration: none;
  white-space: nowrap;

  &:hover:not(:disabled) {
    border: 1px solid var(--ui-primary-color);
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

%button,
%outlined-button {
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    box-shadow: 0 0 0 2px var(--ui-primary-color-lighter);
  }
}

.analytics_tab button:not(.primary),
a.button,
a.secondary_button,
button.secondary {
  @extend %outlined-button;
  display: inline-block;
}

a.primary_button,
button.primary {
  @extend %button;
  display: inline-block;
}

%icon-button {
  background-repeat: no-repeat;
  background-size: size(5) size(5);
  background-position: space(2) space(1.5);
  padding-left: space(9);
}
