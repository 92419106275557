.toggle_locale {
  text-align: right;

  a {
    display: inline-flex;
    align-items: center;

    &::before {
      content: url("#{$images-path}/globe.svg");
      height: space(4);
      margin: 0 space(0.5);
      text-decoration: none;
      display: inline-block;
    }
  }
}
