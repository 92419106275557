.flash {
  padding: space(3) space(4);
  margin: 0 space(8) space(12);
  text-align: center;
  border-radius: rounded(md);
}

.flash_alert {
  background-color: var(--ui-error-surface-color);
  color: var(--ui-on-error-surface-color);
}

.flash_notice {
  background-color: var(--ui-secondary-color-lighter-solid);
  color: var(--ui-secondary-color-dark);
}
