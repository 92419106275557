#active_admin_content {
  padding: 0 space(8);
}

#wrapper {
  background-color: var(--ui-surface-color);
}

.logo_banner {
  background-color: var(--ui-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: space(8);
  height: space(60);

  a {
    display: block;
    background-image: url("#{$images-path}/logo_stacked.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    width: space(32);
    height: space(32);
    overflow: hidden;
    text-indent: -1000rem;
  }
}

#content_wrapper {
  margin: 5rem auto;
  width: 500px;
  position: relative;

  &::before {
    content: "";
    background: url("#{$images-path}/ornament_arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -5rem;
    width: 9rem;
    height: 15rem;
    transform: translateY(-47%);
    left: 110%;
  }

  h2 {
    margin: space(8) 0;
  }
}
