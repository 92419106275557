.spinner {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  text-align: center;

  svg {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    color: var(--ui-primary-color);
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
